import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@components/ProgressBar';
import { Plus, Delete, FavoriteIcon } from '@components/Icons';
import Button from '@components/Button';
import classNames from 'classnames';
import './VideoThumbnail.scss';

const propTypes = {
  src: PropTypes.string,
  thumbnailSrc: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onFavorite: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  isFav: PropTypes.bool,
  filter: PropTypes.string,
};

const defaultProps = {
  showDelete: false,
  onDelete: () => {},
  isFav: false,
};

function VideoThumbnail(props) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState();
  const [playing, setPlaying] = useState();
  const videoElementRef = useRef();
  const durationMinutes = Math.floor((props.duration / 1000) / 60);
  const durationSeconds = Math.floor((props.duration / 1000) % 60);
  const paddedDurationSeconds = `0${durationSeconds}`.slice(-2);
  const videoTimestamp = durationMinutes > 0 ? `${durationMinutes}:${paddedDurationSeconds}m` : `${paddedDurationSeconds}s`;

  const handleLoadedMetadata = () => {
    if (videoElementRef && videoElementRef.current) {
      setDuration(videoElementRef.current.duration);
    }
  };

  const handleTimeUpdate = () => {
    if (videoElementRef && videoElementRef.current) {
      setCurrentTime(videoElementRef.current.currentTime);
    }
  };

  const handleMouseEnter = async () => {
    setPlaying(true);
  };

  const handleMouseLeave = async () => {
    setPlaying(false);
  };

  const renderProgressBar = () => {
    if (!duration) return null;
    return <ProgressBar max={duration} value={currentTime} />;
  };

  const renderDeleteButton = (visible) => {
    if (!visible) return null;

    return (
      <div className="VideoThumbnail__delete" onClick={props.onDelete}>
        <Delete />
      </div>
    );
  };

  useEffect(() => {
    if (videoElementRef.current) {
      videoElementRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElementRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (videoElementRef.current) {
        videoElementRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoElementRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  });

  const favIcon = classNames('VideoThumbnail__controls-fav', { favorited: props.isFav });
  const vidControls = classNames('VideoThumbnail__controls', { on: props.isFav });
  return (
    <div
      className="VideoThumbnail"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='hover-component'> </div>
      <div className="VideoThumbnail__videoContainer">
        {!playing ? (
          <img src={props.thumbnailSrc} alt="" className="VideoThumbnail__video" />
        ) : (
          <video
            loop={true}
            autoPlay={true}
            poster={props.thumbnailSrc}
            className="VideoThumbnail__video"
            ref={videoElementRef}
          >
            <source src={props.src} type="video/mp4" />
          </video>
        )}
        {renderProgressBar()}
      </div>
      <div className={vidControls}>
        <div className="VideoThumbnail__controls-content">
            <Plus />
          {/* {!props.isFav && props.filter === 'stock' && (
            <Button className={favIcon} onClick={e => props.onFavorite(e)}>
              <FavoriteIcon />
            </Button>
          )} */}
          <mark className="VideoThumbnail__controls-timestamp">{videoTimestamp}</mark>
        </div>
      </div>
      {renderDeleteButton(props.showDelete)}
    </div>
  );
}

VideoThumbnail.propTypes = propTypes;
VideoThumbnail.defaultProps = defaultProps;

export default VideoThumbnail;
