import * as types from '@constants/actionTypes';
import axios from 'axios';
// import { EventTypes } from 'redux-segment';
import { handleUnAuthorizedResponse } from '@helpers/auth';
import { modifySourceItem } from '@helpers/backgroundInstance';
import apiService from '@services/api';
// import { prepareCompositionObject } from '@helpers/analytics';
import { setAutosaving } from '@actions/app';
import { addProject, fetchSingleProject } from '@actions/projects';
import { COMPOSITION_GENERATE_URL, COMPOSITION_URL } from '@constants/api';

export const compositionReset = () => ({
  type: types.COMPOSITION_RESET,
  autosave: true,
});

const compositionNotification = notification => ({
  type: 'COMPOSITION_NOTIFICATION',
  notify: notification,
});

export const compositionShowVideoContext = showContext => ({
  type: types.COMPOSITION_SHOW_VIDEO_CONTEXT,
  payload: {
    showContext,
  },
});

export const compositionShowVisiblityHandler = showVisibilityHandler => ({
  type: types.COMPOSITION_SHOW_VISIBLITY_HANDLER,
  payload: {
    showVisibilityHandler,
  },
});

export const formatBackgroundInstances = instances => ({
  type: types.FORMAT_BACKGROUND_INSTANCES,
  autosave: true,
  payload: instances,
});

const videoInstanceAdd = (targetIndex, item) => ({
  type: types.COMPOSITION_VIDEO_INSTANCE_ADD,
  autosave: true,
  undo: true,
  payload: {
    targetIndex,
    item,
  },
});

export const compositionVideoInstanceAdd = (targetIndex, item) => (dispatch, getState) => {
  const {
    maxDuration, duration, width, height,
  } = getState().composition.present;
  if (maxDuration < duration + item.duration) {
    return dispatch(
      compositionNotification({
        message: 'Video can not be added, maximum duration reached',
        kind: 'danger',
      }),
    );
  }
  // modify footage to fit canvas on first load
  const instance = modifySourceItem(item, { width, height });
  return dispatch(videoInstanceAdd(targetIndex, instance));
};

export const compositionVideoInstanceSelect = itemId => ({
  type: types.COMPOSISION_VIDEO_INSTANCE_SELECT,
  payload: {
    itemId,
  },
});

export const compositionSelectTitle = selectedTexts => ({
  type: types.COMPOSITION_SELECT_TITLE,
  payload: {
    selectedTexts,
  },
});

export const compositionVideoInstanceUnselect = () => ({
  type: types.COMPOSISION_VIDEO_INSTANCE_UNSELECT,
});

export const compositionShapelayerUnselect = () => ({
  type: types.COMPOSISION_SHAPELAYER_UNSELECT,
});

const textInstaceAdd = item => ({
  type: types.COMPOSITION_TEXT_INSTANCE_ADD,
  autosave: true,
  undo: true,
  payload: {
    item,
  },
});

export const compositionTextInstaceAdd = item => (dispatch, getState) => {
  const { maxDuration } = getState().composition.present;
  if (item.visibleTo > maxDuration) {
    return dispatch(
      compositionNotification({
        message: 'Text can not be added, maximum duration reached',
        kind: 'danger',
      }),
    );
  }

  return dispatch(textInstaceAdd(item));
};

export const compositionTextLayerAdd = (itemId, layerItem) => ({
  type: types.COMPOSITION_TEXT_LAYER_ADD,
  autosave: true,
  undo: true,
  payload: {
    itemId,
    layerItem,
  },
});

export const compositionLayerAdd = item => ({
  // HOTFIX: Adding layer to Canvas adds it also to timeline
  // Fix VideoPreview.js drop(targetProps, monitor) to real solution
  targetType: 'canvas',
  type: types.COMPOSITION_LAYER_ADD,
  autosave: true,
  undo: true,
  payload: {
    item,
  },
});

export const compositionLayerEditEnter = itemId => ({
  type: types.COMPOSITION_LAYER_EDIT_ENTER,
  payload: {
    itemId,
  },
});

export const compositionLayerEditExit = () => ({
  type: types.COMPOSITION_LAYER_EDIT_EXIT,
});

export const compositionLayerRemove = itemId => ({
  type: types.COMPOSITION_LAYER_REMOVE,
  autosave: true,
  undo: true,
  payload: {
    itemId,
  },
});

export const compositionLayerTimeslotChange = (event, direction, element, delta, id, msRatio) => ({
  type: types.COMPOSITION_LAYER_TIMESLOT_CHANGE,
  autosave: true,
  undo: true,
  payload: {
    delta: delta.width,
    itemId: id,
    direction,
    msRatio,
  },
});

export const compositionLayerToggleAlwaysVisible = (id, alwaysVisible) => ({
  type: types.COMPOSITION_LAYER_ALWAYS_VISIBLE,
  autosave: true,
  undo: true,
  payload: {
    id,
    alwaysVisible,
  },
});

/**
 * Called when a generic layer is modified on canvas
 * @param {object} item - Fabric canvas object (to read position etc from)
 * @param {string} id - Layer id (in composition structure)
 */
export const compositionLayerModify = (item, id) => ({
  type: types.COMPOSITION_LAYER_MODIFY,
  autosave: true,
  undo: true,
  payload: {
    item,
    id,
  },
});

/**
 * Called when a background layer of type image is modified
 * @param {object} item - Fabric canvas object (to read position etc from)
 * @param {string} id - Background instance id (in composition structure)
 */
export const compositionBackgroundInstanceModify = (item, id) => ({
  type: types.COMPOSITION_BACKGROUND_INSTANCE_MODIFY,
  autosave: true,
  undo: true,
  payload: {
    item,
    id,
  },
});

export const compositionLayerInstanceUpdateTimestamps = (item, id) => ({
  type: types.COMPOSITION_LAYER_INSTANCE_UPDATE_TIMESTAMPS,
  autosave: true,
  undo: true,
  payload: {
    item,
    id,
  },
});

export const compositionShapeInstanceUpdateTimestamps = (item, id) => (
  {
    type: types.COMPOSITION_SHAPE_INSTANCE_UPDATE_TIMESTAMPS,
    autosave: true,
    undo: true,
    payload: {
      item,
      id,
    },
  });

export const compositionBackgroundInstanceUpdateTimestamps = (item, id) => ({
  type: types.COMPOSITION_BACKGROUND_INSTANCE_UPDATE_TIMESTAMPS,
  autosave: true,
  undo: true,
  payload: {
    item,
    id,
  },
});

export const compositionTextInstanceUpdateTimestamps = (item, id) => ({
  type: types.COMPOSITION_TEXT_INSTANCE_UPDATE_TIMESTAMPS,
  autosave: true,
  undo: true,
  payload: {
    item,
    id,
  },
});

export const compositionBackgroundInstanceMove = (itemId, left, top) => {
  return ({
    type: types.COMPOSITION_BACKGROUND_INSTANCE_MOVE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      left,
      top,
    },
  });
};

export const compositionVideoInstanceReplace = (targetId, newItem) => ({
  type: types.COMPOSITION_VIDEO_INSTANCE_REPLACE,
  autosave: true,
  undo: true,
  payload: {
    targetId,
    newItem,
  },
});

const splitVideo = timestamp => (dispatch, getState) => {
  const { backgroundInstances } = getState().composition.present;
  const { activeBackgroundId } = getState().sidebar;
  const video = backgroundInstances.filter(instance => instance.id === activeBackgroundId);
  if (video && ((video.visibleTo - timestamp < 1000) ||
    (timestamp - video.visibleFrom < 1000))
  ) {
    return dispatch(compositionNotification({
      message: 'Video can not be splitted, duration too small',
      kind: 'danger',
    }));
  }

  return dispatch({
    type: types.COMPOSITION_VIDEO_INSTANCE_SPLIT,
    autosave: true,
    undo: true,
    payload: {
      timestamp,
      video: video[0],
    },
  });
};

export const compositionVideoInstanceSplit = () => (dispatch, getState) => {
  const { timeline } = getState();

  dispatch(splitVideo(timeline.currentTime));
};

export function compositionVideoInstanceMove(itemId, visibleFrom, visibleTo) {
  return {
    type: types.COMPOSITION_VIDEO_INSTANCE_MOVE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      visibleFrom,
      visibleTo,
    },
  };
}

export function compositionVideoInstanceDragToTimeline(itemId, endPoint, backgroundPosition) {
  return {
    type: types.COMPOSITION_VIDEO_INSTANCE_DRAG_TO_TIMELINE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      endPoint,
      backgroundPosition,
    },
  };
}

export function compositionLayerMove(itemId, left, top) {
  return {
    type: types.COMPOSITION_LAYER_MOVE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
      left,
      top,
    },
  };
}

export function compositionVideoInstanceRemove(itemId) {
  return {
    type: types.COMPOSITION_VIDEO_INSTANCE_REMOVE,
    autosave: true,
    undo: true,
    payload: {
      itemId,
    },
  };
}

export function compositionTextInstanceRemove(sourceId) {
  return {
    type: types.COMPOSITION_TEXT_INSTANCE_REMOVE,
    autosave: true,
    undo: true,
    payload: {
      sourceId,
    },
  };
}

export function compositionVideoGenerateUniqueId(currentId) {
  return {
    type: types.COMPOSITION_VIDEO_GENERATE_UNIQUEID,
    autosave: true,
    undo: true,
    payload: {
      currentId,
    },
  };
}

export function compositionDownload(compositionUid, hasSubscription, purchaseUid) {
  return {
    type: types.COMPOSITION_DOWNLOAD,
    meta: {
      // analytics: {
      //   eventType: EventTypes.track,
      //   eventPayload: {
      //     event: 'Composition Download',
      //     properties: {
      //       compositionUid,
      //       hasSubscription,
      //       purchaseUid,
      //     },
      //   },
      // },
    },
  };
}

export function compositionPlaceholderRemove(currentId) {
  return (dispatch, getState) => {
    const composition = getState().composition.present;
    const index = composition.backgroundInstances.findIndex(item => item.id === currentId);
    // Remove element only if its already added to the timeline.
    // Id drag gets cancelled before the footage is added to the
    // timeline, there is no need to remove it.
    if (index > -1) {
      dispatch(compositionVideoInstanceRemove(index));
    }
  };
}

export function replaceWithUniqueId(sourceIndex, targetIndex, item) {
  return (dispatch) => {
    dispatch(compositionVideoInstanceReplace(sourceIndex, targetIndex, item));
    dispatch(compositionVideoGenerateUniqueId(item.id));
  };
}

export function compositionVideoInstanceResize(id, visibleFrom, visibleTo, playFrom) {
  return {
    type: types.COMPOSITION_VIDEO_INSTANCE_RESIZE,
    autosave: true,
    undo: true,
    payload: {
      itemId: id,
      visibleFrom,
      visibleTo,
      playFrom,
    },
  };
}

export function compositionTextInstanceResize(id, inPoint, outPoint) {
  return {
    type: types.COMPOSITION_TEXT_INSTANCE_RESIZE,
    autosave: true,
    undo: true,
    payload: {
      itemId: id,
      visibleFrom: inPoint,
      visibleTo: outPoint,
    },
  };
}

export function compositionTextInstanceResizeStart(id) {
  return {
    type: types.COMPOSITION_TEXT_INSTANCE_RESIZE_START,
    payload: {
      itemId: id,
    },
  };
}

export function compositionTextInstanceMove(id, inPoint, outPoint, track) {
  return {
    type: types.COMPOSITION_TEXT_INSTANCE_MOVE,
    autosave: true,
    undo: true,
    payload: {
      itemId: id,
      visibleFrom: inPoint,
      visibleTo: outPoint,
      track,
    },
  };
}

export function compositionTextInstanceSwap(id, withId, direction) {
  return {
    type: types.COMPOSITION_TEXT_INSTANCE_SWAP,
    autosave: true,
    undo: true,
    payload: {
      id,
      withId,
      direction,
    },
  };
}

export const duplicateInstances = instanceIds => (dispatch, getState) => {
  const { maxDuration, duration, backgroundInstances } = getState().composition.present;

  const bgInstances = backgroundInstances.filter(instance => instanceIds.includes(instance.id));
  const additionalDuration = bgInstances.reduce((acc, curr) => acc + curr.duration, 0);

  if (maxDuration < duration + additionalDuration) {
    return dispatch(
      compositionNotification({
        message: 'Video can not be added, maximum duration reached',
        kind: 'danger',
      }),
    );
  }

  return dispatch({
    type: types.COMPOSITION_DUPLICATE_INSTANCES,
    payload: {
      instanceIds,
    },
    autosave: true,
    undo: true,
  });
};


export const compositionAddShapeLayer = layerItem => ({
  type: types.COMPOSITION_SHAPE_LAYER_ADD,
  payload: {
    layerItem,
  },
  autosave: true,
  undo: true,
});

export const compositionRemoveShapeLayer = layerId => ({
  type: types.COMPOSITION_SHAPE_LAYER_REMOVE,
  payload: {
    layerId,
  },
  autosave: true,
  undo: true,
});

export const compositionUpdateShapeLayer = (newProperties) => {
  return ({
    type: types.COMPOSITION_SHAPE_LAYER_UPDATE,
    payload: {
      layerId: newProperties.id,
      newProperties,
    },
    autosave: true,
    undo: true,
  });
};

export const compositionToggleClipAudio = itemId => ({
  type: types.COMPOSITION_TOGGLE_CLIP_AUDIO,
  payload: {
    itemId,
  },
  autosave: true,
});

export const requestCompositionLoad = () => ({
  type: types.COMPOSITION_LOAD_REQUEST,
});

export const compositionLoadSuccess = () => ({
  type: types.COMPOSITION_LOAD_SUCCESS,
});

const loadComposition = (composition, isRender, userRole, reversion) => ({
  type: types.COMPOSITION_LOAD,
  payload: {
    composition,
    userRole,
    reversion,
  },
  ...(!isRender && {
    meta: {
      // analytics: {
      //   eventType: EventTypes.track,
      //   eventPayload: {
      //     event: 'Opened Composition',
      //     properties: {
      //       composition: prepareCompositionObject(composition),
      //     },
      //   },
      // },
    },
  }),
});

// Add analytics only if called from non-render location
export const compositionLoad = (composition, isRender, userRole, reversion) =>
  (dispatch) => {
    dispatch(loadComposition(composition, isRender, userRole, reversion));
  };

// Add analytics only if called from non-render location
export const compositionLoadGenerated = composition => ({
  type: types.COMPOSITION_LOAD_GENERATED,
  autosave: true,
  payload: {
    composition,
  },
  meta: {
    // analytics: {
    //   eventType: EventTypes.track,
    //   eventPayload: {
    //     event: 'Generated Composition',
    //     properties: {
    //       composition: prepareCompositionObject(composition),
    //     },
    //   },
    // },
  },
});

export const compositionNotGenerated = (word) => {
  if (word) {
    return {
      type: types.COMPOSITION_NOT_GENERATED,
      notify: {
        message: `Your keyword, ${word} , didn't find any video. Try another one.`,
        kind: 'danger',
        dismissAfter: 8000,
      },
    };
  }
  return {
    type: types.COMPOSITION_NOT_GENERATED,
    notify: {
      message:
        "Oops! We couldn't create a video with your keywords. Please try again with less keywords",
      kind: 'danger',
      dismissAfter: 8000,
    },
  };
};

export const compositionDownloadSet = composition => ({
  type: types.COMPOSITION_DOWNLOAD_SET,
  payload: {
    composition,
  },
});

export const compositionDownloadClear = uid => ({
  type: types.COMPOSITION_DOWNLOAD_CLEAR,
  payload: {
    uid,
  },
});

export const requestCompositionSave = () => ({
  type: types.COMPOSITION_SAVE_REQUEST,
});

export const compositionSaveSuccess = () => async (dispatch, getState) => {
  setTimeout(() => {
    dispatch(setAutosaving(false));
  }, 3000);
  const { sidebar } = getState();
  const { activeProjectUid } = sidebar;
  dispatch(fetchSingleProject(activeProjectUid));
  return {
    type: types.COMPOSITION_SAVE_SUCCESS,
  };
};

export const requestCompositionRename = () => ({
  type: types.COMPOSITION_RENAME_REQUEST,
});

export const compositionRenameSuccess = (projectUid, newName, videoUid) => ({
  type: types.COMPOSITION_RENAME_SUCCESS,
  payload: {
    projectUid,
    newName,
    videoUid,
  },
  autosave: true,
});

export const requestCompositionGenerate = () => ({
  type: types.COMPOSITION_GENERATE_REQUEST,
});

export const compositionSetGenerateTag = tag => ({
  type: types.COMPOSITION_SET_TAG,
  payload: {
    tag,
  },
});

export const compositionSetGenerateLogo = logoData => ({
  type: types.COMPOSITION_SET_LOGO,
  payload: {
    logoData,
  },
});

export const compositionSetOptions = newOptions => ({
  type: types.COMPOSITION_SET_OPTIONS,
  payload: {
    newOptions,
  },
});

export const compositionRemoveGenerateTag = removableTag => ({
  type: types.COMPOSITION_REMOVE_TAG,
  payload: {
    removableTag,
  },
});

export const compositionGenerate = (generatorOptions, projectUid) => async (dispatch) => {
  dispatch(requestCompositionGenerate());

  const response = await axios
    .post(COMPOSITION_GENERATE_URL, generatorOptions)
    .catch(handleUnAuthorizedResponse);
  if (response) {
    if (!projectUid) {
      dispatch(addProject('My new Project', response.data.composition));
    } else {
      dispatch(compositionLoadGenerated(response.data.composition));
    }
  } else {
    dispatch(compositionNotGenerated());
  }
};

export const loadCompositionObject = (jsondata) => async (dispatch, getState) => {
  dispatch(compositionLoad(jsondata, false, 1));
}

export const fetchAndLoadComposition = (uid, isRender = false) => async (dispatch, getState) => {
  const response = await apiService
    .get(`${COMPOSITION_URL}/${uid}?render=${isRender}`)
    .catch(handleUnAuthorizedResponse);
  const { user } = getState();
  if (response) dispatch(compositionLoad(response.data.data, isRender, user.role));
};

export const fetchDownloadComposition = (uid, posterFrame) => async (dispatch) => {
  dispatch(compositionDownloadClear(uid));
  const response = await axios.get(`${COMPOSITION_URL}/${uid}`).catch(handleUnAuthorizedResponse);

  dispatch(compositionDownloadSet({ ...response.data.data, posterFrame }));
};

export const saveComposition = () => async (dispatch, getState) => {
  // Disabled autosaving by disabling saving entirely
  // const composition = getState().composition.present;
  // window.localStorage.setItem('autosave', JSON.stringify(composition))
  // if (!composition.uid || window.appIsRendering) return;
  // dispatch(setAutosaving(true));
  // dispatch(requestCompositionSave());

  // const { uid } = composition;

  // await axios
  //   .patch(`${COMPOSITION_URL}/${uid}`, { content: composition })
  //   .catch(handleUnAuthorizedResponse);

  // dispatch(compositionSaveSuccess());
};

export const renameComposition = (newName, compositionUid, projectUid) => async (dispatch) => {
  dispatch(requestCompositionRename());

  const composition = { name: newName };

  await axios
    .patch(`${COMPOSITION_URL}/${compositionUid}`, composition)
    .catch(handleUnAuthorizedResponse);

  dispatch(compositionRenameSuccess(projectUid, newName, compositionUid));
};
