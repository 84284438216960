/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from '@components/ConfirmDialog';
import Button from '@components/Button';
import { RotateLeft } from '@components/Icons';
import { checkEventType } from '@helpers/textLayer';
import { getStateObjectFromEvent } from '@helpers/textInstance';
import Text from './Text';
import TextEditor from './TextEditor';
import './TitleLayer.scss';

const propTypes = {
  textInstance: PropTypes.object.isRequired,
  playing: PropTypes.bool.isRequired,
  selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  visible: PropTypes.bool.isRequired,
  canvas: PropTypes.object.isRequired,
  scaleFactor: PropTypes.number.isRequired,
  onTextLayerMove: PropTypes.func.isRequired,
  onTextLayerRotate: PropTypes.func.isRequired,
  onTextLayerSelect: PropTypes.func.isRequired,
  onTextLayerChange: PropTypes.func.isRequired,
  onTextLayerScale: PropTypes.func.isRequired,
  onTextLayerSmartResize: PropTypes.func,
  onSetGuides: PropTypes.func.isRequired,
  onTextRemove: PropTypes.func.isRequired,
  toolbarActive: PropTypes.bool,
  compositionSource: PropTypes.string,
  compositionDimention: PropTypes.object,
};

let prevGuides;

class TitleLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isMoving: false,
      isResizing: false,
      isRotating: false,
      isSmartResizing: false,
      smartResizeDirection: null,
      resizeDirection: null,
      clickHappening: false,
      savedWidth: null,
      scaleX: null,
      scaleY: null,
      width: null,
      startWidth: null,
      startFontSize: null,
      newWidth: null,
      newFontSize: null,
      selectedTextWidth: null,
      selectedTextHeight: null,
      x: props.textInstance.left,
      y: props.textInstance.top,
      dragStart: {
        x: null,
        y: null,
      },
      diffX: null,
      diffY: null,
      height: null,
      initialHeight: null,
      ang: null,
      rotateHandleAngle: null,
      counterVisible: false,
      centerX: null,
      centerY: null,
      warningVisible: false,
      lastInputChar: '',
      lastCharWidth: null,
    };
  }

  componentDidMount() {
    if (!this.divElement) return;
    window.addEventListener('keydown', this.keyboardShortcuts);
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    const { textInstance, compositionDimention, compositionSource } = this.props;
    const { ang: angle, scaleX } = textInstance;
    this.setState({
      height, initialHeight: height, width, ang: angle,
    });
    // for generated text instances we need to optimize dimensions
    // to avoid overflow of text beyond canvas width
    const safeWidth = compositionDimention.width * 0.8;

    if (width >= safeWidth && scaleX && compositionSource === 'Generator') {
      const newTextScale = (safeWidth * scaleX) / width;
      this.props.onTextLayerScale(textInstance.id, newTextScale, newTextScale, true);
      this.props.onTextLayerSmartResize(textInstance.id, (width + 10),
        textInstance.fontSize);
    } else if (!textInstance.width) {
      // COMBAK: the following is necessary because some Old
      // text instances have no width, the same is done in componentDidUpdate
      this.props.onTextLayerSmartResize(textInstance.id, (width + 10),
        textInstance.fontSize);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.divElement) return;
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    const { textInstance } = this.props;
    if (!textInstance.width) {
      this.props.onTextLayerSmartResize(textInstance.id, (width + 10),
        textInstance.fontSize);
    }
    if (this.state.initialHeight !== height) {
      this.setState({ initialHeight: height });
    }

    if (this.props.textInstance.ang !== prevProps.textInstance.ang) {
      this.setState({ ang: this.props.textInstance.ang });
    }
    if (this.props.textInstance.fontSize < prevProps.textInstance.fontSize) {
      const textWidth = document.getElementsByClassName('text')[0].offsetWidth + this.props.textInstance.padding;
      this.props.onTextLayerSmartResize(this.props.textInstance.id, (textWidth * 1.3),
        this.props.textInstance.fontSize);
    }
    // If textInstance has changed, update some state values
    if (JSON.stringify(prevProps.textInstance) !== JSON.stringify(this.props.textInstance)) {
      this.setState({
        x: this.props.textInstance.left,
        y: this.props.textInstance.top,
      });
    }
  }

  keyboardShortcuts = (event) => {
    const {
      selected,
      onTextLayerMove,
      textInstance,
      toolbarActive,
    } = this.props;
    const { isEditing, isMoving, isResizing } = this.state;
    if (selected && !isEditing && !isMoving && !isResizing && !toolbarActive) {
      switch (event.keyCode) {
        case 39:
          if (event.shiftKey) {
            onTextLayerMove(textInstance.id, textInstance.left + 100, textInstance.top);
          } else {
            onTextLayerMove(textInstance.id, textInstance.left + 10, textInstance.top);
          }
          break;
        case 37:
          if (event.shiftKey) {
            onTextLayerMove(textInstance.id, textInstance.left - 100, textInstance.top);
          } else {
            onTextLayerMove(textInstance.id, textInstance.left - 10, textInstance.top);
          }
          break;
        case 38:
          if (event.shiftKey) {
            onTextLayerMove(textInstance.id, textInstance.left, textInstance.top - 100);
          } else {
            onTextLayerMove(textInstance.id, textInstance.left, textInstance.top - 10);
          }
          break;
        case 40:
          if (event.shiftKey) {
            onTextLayerMove(textInstance.id, textInstance.left, textInstance.top + 100);
          } else {
            onTextLayerMove(textInstance.id, textInstance.left, textInstance.top + 10);
          }
          break;
        default:
      }
    }
  }

  getElementSize(element) {
    return {
      width: element.offsetWidth + 10,
      height: element.offsetHeight,
    };
  }

  handleDoubleClick = () => {
    this.setState({
      clickHappening: false,
      isMoving: false,
      // In case of double Click event:
      isEditing: true,
    });
  }

  mouseDown = (e) => {
    // prevent user action if editing more is on
    if (this.state.isEditing) return;
    const { textInstance, onTextLayerSelect } = this.props;
    onTextLayerSelect(textInstance.id);
    this.dragStartX = e.pageX;
    this.dragStartY = e.pageY;
    this.offsetX = e.nativeEvent.offsetX;
    this.offsetY = e.nativeEvent.offsetY;
    // Check if user clicked on resizers from event parameter, using checkEventType helper
    const event = checkEventType(e);
    // prepare state for moving, rotating, or resizing based on event type
    const stateObject = getStateObjectFromEvent(event);
    if (event.type === 'smartResizing') {
      const el = document.querySelector(`.htmlTextInstance-${textInstance.id}`);
      this.setState({
        ...stateObject,
        startWidth: el.offsetWidth,
        startFontSize: textInstance.fontSize,
      });
    } else {
      this.setState(stateObject);
    }
    // also set current dom element width and height of text layer
    this.setState({
      selectedTextWidth: this.getElementSize(e.target).width,
      selectedTextHeight: this.getElementSize(e.target).height,
    });
    // Spin up listeners
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
  }

  mouseMove = (e) => {
    const {
      canvas, scaleFactor, onSetGuides, textInstance,
    } = this.props;
    const {
      isMoving,
      isRotating,
      isResizing,
      isSmartResizing,
      selectedTextWidth,
      selectedTextHeight,
      smartResizeDirection,
    } = this.state;
    if (isMoving) {
      // If moving text calculate new position coords of text layer on canvas
      const newX = ((e.pageX - canvas.left) / scaleFactor) - (this.offsetX * textInstance.scaleX);
      const newY = ((e.pageY - canvas.top) / scaleFactor) - (this.offsetY * textInstance.scaleY);
      this.setState({
        x: newX + ((selectedTextWidth * textInstance.scaleX) / 2),
        y: newY + ((selectedTextHeight * textInstance.scaleY) / 2),
      });

      // Snap with edges, center lines and show guides
      const guides = this.toggleGuides();
      if (guides) onSetGuides(guides);
    }

    // deepending on state change run resizermove(resizing scale), or rotatemove
    // or smart resizing (resizing width)
    if (isResizing) {
      this.resizeMove(e);
    }
    if (isRotating) {
      this.rotateMove(e);
    }
    if (isSmartResizing) {
      this.resizeSmartMove(e, smartResizeDirection);
    }
  }

  mouseUp = (e) => {
    // mouse draggind ended
    const {
      isMoving, isRotating, isResizing, isSmartResizing,
    } = this.state;

    const {
      textInstance, onTextLayerMove, onSetGuides,
    } = this.props;
    if (isMoving) {
      // update positions of textInstance in the redux store
      onTextLayerMove(textInstance.id, this.state.x, this.state.y);
      onSetGuides({
        showGuideX: false,
        showGuideY: false,
        isMoving: false,
      });
    }
    // deepending on event types, set ending to update redux store
    if (isResizing) {
      this.resizeEnd();
    }

    if (isRotating) {
      this.rotateEnd(e);
    }

    if (isSmartResizing) {
      this.resizeSmartEnd(e);
    }
    // remove event listeners
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
  }

  // Resize changes SCALE
  resizeMove = (e) => {
    const { scaleFactor, canvas, textInstance } = this.props;
    const {
      isResizing,
      selectedTextWidth,
      x,
      y,
      height,
    } = this.state;
    const centerX = x + (selectedTextWidth / 2);
    const centerY = y + (height / 2);
    const shapeCenterX = canvas.left + (centerX * scaleFactor);
    const shapeCenterY = canvas.top + (centerY * scaleFactor);
    if (isResizing) {
      const diffXStart = ((this.dragStartX - shapeCenterX));
      const diffYStart = ((this.dragStartY - shapeCenterY));
      const diffX = ((e.pageX - shapeCenterX));
      const diffY = ((e.pageY - shapeCenterY));
      const pythagorasEnd = Math.sqrt((diffY ** 2) + (diffX ** 2));
      const pythagorasStart = Math.sqrt((diffXStart ** 2) + (diffYStart ** 2));
      const newScale = (pythagorasEnd / pythagorasStart);
      const currentScale = textInstance.scaleX || 1;

      const newScaleX = newScale * currentScale;

      if (newScaleX !== 1) {
        this.setState({
          scaleX: newScaleX,
          scaleY: newScaleX,
          x: this.state.x,
          y: this.state.y,
        });
      }
    }
  }

  resizeEnd = () => {
    const { isResizing, scaleX, scaleY } = this.state;
    const { textInstance, onTextLayerScale } = this.props;
    if (isResizing && scaleX) {
      onTextLayerScale(textInstance.id, scaleX, scaleY);
      this.setState({
        isResizing: false,
        scaleX,
        scaleY,
      });
    }
  }

  // Smart Resize changes WIDTH and FONTSIZE
  resizeSmartMove = (e) => {
    const {
      isSmartResizing,
      startWidth,
      startFontSize,
      smartResizeDirection,
    } = this.state;
    if (isSmartResizing) {
      const { scaleFactor } = this.props;
      const diffX = ((e.pageX - this.dragStartX) / scaleFactor);
      const diffY = ((e.pageY - this.dragStartY) / scaleFactor);
      switch (smartResizeDirection) {
        case 'both':
          this.setState({
            newWidth: startWidth + (diffX / scaleFactor),
            newFontSize: 1 + ((1 / startFontSize) * diffY),
          });
          break;
        case 'horizontal-right':
          this.setState({
            newWidth: startWidth + (diffX / scaleFactor),
            newFontSize: 1 + ((1 / startFontSize)),
          });
          break;
        case 'horizontal-left':
          this.setState({
            newWidth: startWidth + ((diffX * -1) / scaleFactor),
            newFontSize: 1 + ((1 / startFontSize)),
          });
          break;
        case 'vertical':
          this.setState({
            newWidth: startWidth,
            newFontSize: 1 + ((1 / startFontSize) * diffY),
          });
          break;
        default:
          break;
      }
    }
  }

  resizeSmartEnd = () => {
    const {
      startWidth,
      newWidth,
      newFontSize,
      isSmartResizing,
      smartResizeDirection,
    } = this.state;
    if (isSmartResizing) {
      const { textInstance, onTextLayerSmartResize } = this.props;
      if (smartResizeDirection === 'both' || smartResizeDirection === 'both-right') {
        onTextLayerSmartResize(textInstance.id, newWidth,
          parseInt((textInstance.fontSize * newFontSize).toFixed(1), 10));
      }
      if (smartResizeDirection === 'horizontal-right') {
        onTextLayerSmartResize(textInstance.id, newWidth,
          textInstance.fontSize);
      }
      if (smartResizeDirection === 'horizontal-left') {
        onTextLayerSmartResize(textInstance.id, newWidth,
          textInstance.fontSize);
      }
      if (smartResizeDirection === 'vertical') {
        onTextLayerSmartResize(textInstance.id, startWidth,
          parseInt((textInstance.fontSize * newFontSize).toFixed(1), 10));
      }
      this.setState({
        isSmartResizing: false,
        width: newWidth,
      });
    }
  }

  rotateMove = (e) => {
    const {
      isRotating,
      height,
    } = this.state;
    const {
      textInstance,
    } = this.props;
    const { scaleFactor } = this.props;
    if (isRotating) {
      const canvasCoords = document.getElementsByClassName('upper-canvas')[0].getBoundingClientRect();
      const shapeCenterX = canvasCoords.x + (textInstance.left * scaleFactor);
      const shapeCenterY = canvasCoords.y + (textInstance.top * scaleFactor);
      // Calculate the vectors from center to straight down and e.page coordinates
      const vectorS = { x: 0, y: (height * scaleFactor) / 2 };
      const vectorE = { x: (e.pageX - shapeCenterX), y: (e.pageY - shapeCenterY) };
      const dotProduct = ((vectorS.x * vectorE.x) + (vectorS.y * vectorE.y));
      const crossProduct = ((vectorS.x * vectorE.y) + (vectorS.y * vectorE.x));
      const radian = Math.atan2(Math.abs(crossProduct), dotProduct) / Math.PI;
      const multiplier = e.pageX > shapeCenterX ? -1 : 1;
      let angle = radian * (180 * multiplier);
      // SNAP logic
      if (Math.round(angle) % 45 === 0) { angle = Math.round(angle); }
      this.setState({
        ang: angle,
        rotateHandleAngle: angle * -1,
      });
    }
  }

  rotateEnd = () => {
    const { textInstance, onTextLayerRotate } = this.props;
    this.setState({
      isRotating: false,
      isResizing: false,
      isMoving: false,
      isPressing: false,
      isVisible: false,
      counterVisible: false,
    });
    onTextLayerRotate(textInstance.id, this.state.ang);
  }

  toggleGuides = () => {
    const {
      textInstance, canvas, scaleFactor,
    } = this.props;
    // Get dimensions of the element atm
    const element = document.querySelector(`.htmlTextInstance-${textInstance.id}`);
    const left = element && element.getBoundingClientRect().left - canvas.left;
    const top = element && element.getBoundingClientRect().top - canvas.top;
    const width = element && element.offsetWidth;
    const height = element && element.offsetHeight;

    // Snap with edges, center lines and show guides
    const SNAP_RADIUS = 10;
    const objectCenterX = ((canvas.width / scaleFactor) / 2) -
      ((width * textInstance.scaleX) / 2).toFixed(0);
    const objectCenterY = ((canvas.height / scaleFactor) / 2) -
      ((height * textInstance.scaleY) / 2).toFixed(0);
    const canvasWidth = (canvas.width / scaleFactor) - (width * textInstance.scaleX);
    const canvasHeight = (canvas.height / scaleFactor) - (height * textInstance.scaleY);
    const guides = {
      showGuideX: false,
      showGuideY: false,
    };

    if ((left / scaleFactor) > (-SNAP_RADIUS) &&
      (left / scaleFactor) < (SNAP_RADIUS)) {
      this.setState({
        x: 0 + ((width * textInstance.scaleX) / 2),
      });
    }
    if ((left / scaleFactor) > (canvasWidth - SNAP_RADIUS) &&
      (left / scaleFactor) < (canvasWidth + SNAP_RADIUS)) {
      this.setState({
        x: (canvas.width / scaleFactor) - ((width * textInstance.scaleX) / 2),
      });
    }
    if ((top / scaleFactor) > (-SNAP_RADIUS) &&
      (top / scaleFactor) < (SNAP_RADIUS)) {
      this.setState({
        y: 0 + ((height * textInstance.scaleY) / 2),
      });
    }
    if ((top / scaleFactor) > (canvasHeight - SNAP_RADIUS) &&
      (top / scaleFactor) < (canvasHeight + SNAP_RADIUS)) {
      this.setState({
        y: (canvas.height / scaleFactor) - ((height * textInstance.scaleY) / 2),
      });
    }

    if ((left / scaleFactor) > (objectCenterX - SNAP_RADIUS) &&
      (left / scaleFactor) < (objectCenterX + SNAP_RADIUS)) {
      this.setState({
        x: objectCenterX + ((width * textInstance.scaleX) / 2),
      });
      guides.showGuideY = true;
    } else {
      guides.showGuideY = false;
    }
    if ((top / scaleFactor) > (objectCenterY - SNAP_RADIUS) &&
      (top / scaleFactor) < (objectCenterY + SNAP_RADIUS)) {
      this.setState({
        y: objectCenterY + ((height * textInstance.scaleY) / 2),
      });
      guides.showGuideX = true;
    } else {
      guides.showGuideX = false;
    }
    if (JSON.stringify(guides) !== JSON.stringify(prevGuides)) {
      prevGuides = guides;
      return guides;
    }
  }

  handleTextExitEditing = (e) => {
    const { textInstance, onTextRemove, onTextLayerChange } = this.props;
    // eslint-disable-next-line no-restricted-globals
    if (textInstance.animation && textInstance.animation.name === 'runNumber' && isNaN(e.target.value)) {
      this.setState({ warningVisible: true });
    }
    this.setState({
      isEditing: false,
      isMoving: false,
      clickHappening: false,
    });
    if (e.target.value === '') {
      onTextRemove(textInstance.id);
    } else {
      onTextLayerChange(textInstance.id, e.target.value);
    }
  }


  render() {
    const {
      canvas,
      scaleFactor,
      textInstance,
      playing,
      visible,
      selected,
    } = this.props;

    const {
      isEditing,
      isMoving,
      isResizing,
      isRotating,
      scaleX,
      ang,
      warningVisible,
      isSmartResizing,
      newWidth,
      newFontSize,
      width,
    } = this.state;

    if (textInstance.hidden) {
      return null;
    }

    const resizingOpacity = (isSmartResizing || isResizing || isRotating) ? 0 : 1;
    // const angle = ang > -30 || ang < 30 ? 0 : ang;

    let justifyContent;
    if (textInstance.textAlign === 'center') {
      justifyContent = 'center';
    } else if (textInstance.textAlign === 'left') {
      justifyContent = 'flex-start';
    } else if (textInstance.textAlign === 'right') {
      justifyContent = 'flex-end';
    }

    // const counterVisible = this.state.counterVisible ? 'block' : 'none';

    const { type } = textInstance;

    const centerX = (canvas.width / scaleFactor) / 2;
    const centerY = (canvas.height / scaleFactor) / 2;

    const borderColor = textInstance.borderWidth && textInstance.borderColor && `rgba(${textInstance.borderColor.r}, ${textInstance.borderColor.g}, ${textInstance.borderColor.b}, ${textInstance.borderColor.a})`;
    const boxShadow = textInstance.boxShadow && `${textInstance.boxShadow.offsetX}px ${textInstance.boxShadow.offsetY}px ${textInstance.boxShadow.blur}px ${textInstance.boxShadow.color}`;

    const shadowConstructor = () => {
      let shadowString;
      if (borderColor && textInstance.borderWidth && !boxShadow) {
        shadowString = `${borderColor} 0px 0px 0px ${textInstance.borderWidth}px inset`;
      }
      if (borderColor && textInstance.borderWidth && boxShadow) {
        shadowString = `${borderColor} 0px 0px 0px ${textInstance.borderWidth}px inset, ${boxShadow}`;
      }
      if ((!borderColor || !textInstance.borderWidth) && boxShadow) {
        shadowString = `${boxShadow}`;
      }
      return shadowString;
    };

    // Set up layer properties (depending on type)
    const getLayerStyleProps = () => ({
      height: 'auto',
      padding: textInstance.padding || '10px 20px',
      justifyText: textInstance.textAlign === 'center' ? 'center' : null,
      backgroundColor: textInstance.backgroundColor,
      boxBorderRadius: `${textInstance.borderRadius / 1.2}px`,
    });

    return (
      <div
        ref={(divElement) => { this.divElement = divElement; }}
        className={`htmlTextInstance-${textInstance.id}`}
        onMouseDown={this.mouseDown}
        onDoubleClick={this.handleDoubleClick}
        style={{
          position: 'absolute',
          pointerEvents: visible ? 'all' : 'none',
          cursor: 'move',
          color: textInstance.fill,
          width: isSmartResizing ? `${newWidth}px` : `${textInstance.width}px`,
          textTransform: textInstance.textTransform,
          fontFamily: textInstance.fontFamily,
          fontSize: isSmartResizing && newFontSize ? `${textInstance.fontSize * newFontSize}px` : `${textInstance.fontSize}px`,
          fontStyle: textInstance.fontStyle,
          fontWeight: textInstance.fontWeight,
          lineHeight: textInstance.lineHeight || '1em',
          left: isMoving ? `${this.state.x}px` : `${textInstance.left || centerX}px`,
          top: isMoving ? `${this.state.y}px` : `${textInstance.top || centerY}px`,
          textAlign: textInstance.textAlign,
          WebkitTextStrokeWidth: `${textInstance.strokeWidth || 0}px`,
          WebkitTextStrokeColor: `${textInstance.stroke}`,
          borderRadius: `${textInstance.borderRadius}px`,
          textShadow: textInstance.textShadow ? `${textInstance.textShadow.offsetX}px ${textInstance.textShadow.offsetY}px ${textInstance.textShadow.blur}px ${textInstance.textShadow.color}` : null,
          transform: `scaleX(${isResizing && scaleX ? scaleX : textInstance.scaleX}) scaleY(${isResizing ? scaleX : textInstance.scaleX}) rotate(${ang || 0}deg) translate(-50%, -50%)`,
          transformOrigin: '0 0',
          margin: '-2px 0',
          whiteSpace: textInstance.width || isSmartResizing ? 'normal' : 'nowrap',
          // zIndex: textInstance.depth + 1 || 1,
          display: 'flex',
          opacity: (playing || visible || window.appIsRendering) ? '1' : '0',
          padding: 0,
          backgroundColor: 'unset',
        }}
      >
        <div
          className="backgroundColorLayer"
          style={{
            boxShadow: shadowConstructor(),
            backgroundColor: getLayerStyleProps().backgroundColor,
            borderRadius: getLayerStyleProps().boxBorderRadius,
          }}
        ></div>
        <div
          className="backgroundColor"
          style={{
            display: 'flex',
            width: '100%',
            padding: getLayerStyleProps().padding,
            justifyContent,
            border: selected ? '2px solid var(--color1)' : 'none',
          }}
        >
          {selected && <div className="htmlTextInstance_controls" style={{ opacity: resizingOpacity, transition: 'opacity .25s' }}>
            <div className="resizerH-left" style={{ right: `${(isSmartResizing || isResizing) ? newWidth - 4 : width - 4}px`, transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }} />
            <div className="resizerH-right" style={{ transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }} />
            {/* <div className="resizerV" onMouseDown={this.resizeSmartStart} /> */}
            <div className="resizer-br" style={{ transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }} />
            <div className="resizer-bl" style={{ transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }} />
            <div className="resizer-tl" style={{ transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }} />
            <div className="resizer-tr" style={{ transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }} />
            <div className="rotateHandle" style={{ transform: `scale(${1 / textInstance.scaleX}, ${1 / textInstance.scaleX})` }}>
              <RotateLeft />
            </div>
            {/* // TODO: not visible yet, change asap
            <div className="rotateCounter" style={
              {
                transform: `rotate(${ang * -1}deg)`,
                display: counterVisible,
              }
              }>
                <span>
                  {Math.round(ang)}
                </span>
            </div> */}
          </div>}
          {type === 'text' && !isEditing &&
            <Text
            textInstance={textInstance}
              content={textInstance.text}
              padding={textInstance.padding}
              angle={textInstance.angle}
              textAlign={textInstance.textAlign}
            />
          }
          {type === 'text' && isEditing &&
            <TextEditor
            textInstance={textInstance}
            onBlur={this.handleTextExitEditing}
              defaultValue={textInstance.text}
              initialHeight={this.state.initialHeight}
            />
          }
        </div>
        <ConfirmDialog
          visible={warningVisible}
          title={'Value is not a number'}
          onClose={() => this.setState({ warningVisible: false })}
          confirmButton={
            <Button color="pink" onClick={() => this.setState({ warningVisible: false })}>
              Understood.
            </Button>
          }
        >
          Your selected animation type [Run Number] only works with numeric values on single line.
          This text instance contains either text or more than one line.
        </ConfirmDialog>
      </div>
    );
  }
}

TitleLayer.propTypes = propTypes;

export default TitleLayer;
