import WebFont from 'webfontloader';

const fontOptions = ["Times New Roman",
  "Georgia",
  "Palatino Linotype",
  "Palatino",
  "Arial",
  "Helvetica",
  "Verdana",
  "Trebuchet MS",
  "Courier New",
  "Lucida Console",
  "Comic Sans MS"].map(font => ({ value: font, label: font, className: '' }));


const getFontOptions = (brandFonts) => {
  return fontOptions
};

const fontSizeAndOptionStyles = (brandFonts, option) => ({
  control: provided => ({
    ...provided,
    color: 'var(--color2)',
    backgroundColor: "var(--color4)",
    fontSize: '12px',
    boxShadow: 'none',
    height: 32,
    minHeight: "none",
    border: "none",
    ':hover': {
      backgroundColor: "var(--color3)"
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0px 8px',
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "var(--color5)",
    boxShadow: "none",
  }),
  menuList: styles => ({
    ...styles,
    color: "var(--color2)",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "var(--color1)" : styles[":isSelected"],
    ':hover': {
      backgroundColor: "var(--color4)"
    },
  }),
  placeholder: styles => ({
    ...styles,
    color: 'var(--color2)',
  }
  ),
  singleValue: provided => ({
    fontFamily: option,
    ...provided,
    fontSize: '14px',
    color: 'var(--color2)',


  }),

  input: (provided, props) => {
    const fontFamily = props.value;
    return {
      ...provided,
      fontFamily,
      color: 'var(--color2)',
    };
  },


});

const fontSizeOptions = [
  { value: 48, label: '48' },
  { value: 60, label: '60' },
  { value: 72, label: '70' },
  { value: 96, label: '96' },
  { value: 110, label: '110' },
  { value: 150, label: '150' },
  { value: 200, label: '200' },
  { value: 250, label: '250' },
  { value: 300, label: '300' },
  { value: 350, label: '350' },
  { value: 400, label: '400' },
];

const colorStyles = {
  control: styles => ({
    ...styles,
    color: '#111',
    fontSize: '12px',
    maxWidth: '4.5rem',
    boxShadow: 'none',
  }),

  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),

  option: styles => ({
    ...styles,
    color: '#111',
    fontSize: '12px',
  }),
  menu: styles => ({
    ...styles,
    maxWidth: '4.5rem',
  }),

  placeholder: styles => ({
    ...styles,
    color: '#111',
  }),
};

const getFonts = fontArray => fontArray.map(fontObject => fontObject.value);

const loadWebFonts = async () => {

  const families = getFonts(fontOptions);

  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families,
      },
      timeout: 10000,
      // loading: () => console.log('All Fonts loading'),
      active: () => {
        resolve(true);
      },
      inactive: () => reject(),
      // fontloading: (familyName, fvd) => console.log('Font loading', familyName, fvd),
      // fontactive: (familyName, fvd) => console.log('Font active', familyName, fvd),
      // fontinactive: (familyName, fvd) => console.log('Font inactive', familyName, fvd),
    });
  });
};

export {
  loadWebFonts,
  fontOptions,
  fontSizeAndOptionStyles,
  fontSizeOptions,
  getFontOptions,
  colorStyles,
};
