import * as types from '@constants/actionTypes';
import { stateKeys } from '@constants/footage';
import { removeEntryByUid } from '@reducers/utils';
const stockImagesUrl = "https://storage.googleapis.com/stock-library/images";
const stockVideosUrl = "https://storage.googleapis.com/stock-library/videos";
const initialState = {
  searchTerm: '',
  searchSuggestions: [],
  relatedTags: [],
  searchIsFetching: false,
  sorting: 'popular',
  stockImages: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      {
        "src": stockImagesUrl + '/fantasy/pexels-alinari-9677984.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-alinari-9677984.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-alinari-9677984.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-rdne-7978124.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-rdne-7978124.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-rdne-7978124.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-cottonbro-7354808.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-cottonbro-7354808.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-cottonbro-7354808.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-thatguycraig000-3359734.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-thatguycraig000-3359734.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-thatguycraig000-3359734.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-jplenio-1165982.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-jplenio-1165982.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-jplenio-1165982.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-tu-nguy-n-709154-1545590.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-tu-nguy-n-709154-1545590.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-tu-nguy-n-709154-1545590.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-leticia-azevedo-95410198-9341937.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-leticia-azevedo-95410198-9341937.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-leticia-azevedo-95410198-9341937.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-weirdfish-2640604.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-weirdfish-2640604.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-weirdfish-2640604.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/fantasy/pexels-pixabay-326055.jpg',
        "width": 1920,
        "height": 1080,
        "category": "fantasy",
        "thumbnail": {
          "hd": stockImagesUrl + '/fantasy/pexels-pixabay-326055.jpg',
          "small": stockImagesUrl + '/fantasy/pexels-pixabay-326055.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-elevate-1267320.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-elevate-1267320.jpg',
          "small": stockImagesUrl + '/food/pexels-elevate-1267320.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-pixabay-260922.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-pixabay-260922.jpg',
          "small": stockImagesUrl + '/food/pexels-pixabay-260922.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-ella-olsson-572949-1640777.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-ella-olsson-572949-1640777.jpg',
          "small": stockImagesUrl + '/food/pexels-ella-olsson-572949-1640777.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-pixabay-262047.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-pixabay-262047.jpg',
          "small": stockImagesUrl + '/food/pexels-pixabay-262047.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-elletakesphotos-2696064.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-elletakesphotos-2696064.jpg',
          "small": stockImagesUrl + '/food/pexels-elletakesphotos-2696064.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-pixabay-262918.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-pixabay-262918.jpg',
          "small": stockImagesUrl + '/food/pexels-pixabay-262918.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-fotios-photos-1279330.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-fotios-photos-1279330.jpg',
          "small": stockImagesUrl + '/food/pexels-fotios-photos-1279330.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-pixabay-262978.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-pixabay-262978.jpg',
          "small": stockImagesUrl + '/food/pexels-pixabay-262978.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-janetrangdoan-1128678.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-janetrangdoan-1128678.jpg',
          "small": stockImagesUrl + '/food/pexels-janetrangdoan-1128678.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/food/pexels-vanmalidate-769289.jpg',
        "width": 1920,
        "height": 1080,
        "category": "food",
        "thumbnail": {
          "hd": stockImagesUrl + '/food/pexels-vanmalidate-769289.jpg',
          "small": stockImagesUrl + '/food/pexels-vanmalidate-769289.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-jeremy-bishop-1260133-2397651.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-jeremy-bishop-1260133-2397651.jpg',
          "small": stockImagesUrl + '/nature/pexels-jeremy-bishop-1260133-2397651.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-pixabay-414837.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-pixabay-414837.jpg',
          "small": stockImagesUrl + '/nature/pexels-pixabay-414837.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-jeremy-bishop-1260133-2765872.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-jeremy-bishop-1260133-2765872.jpg',
          "small": stockImagesUrl + '/nature/pexels-jeremy-bishop-1260133-2765872.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-pixabay-459728.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-pixabay-459728.jpg',
          "small": stockImagesUrl + '/nature/pexels-pixabay-459728.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-mikhail-nilov-7709900.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-mikhail-nilov-7709900.jpg',
          "small": stockImagesUrl + '/nature/pexels-mikhail-nilov-7709900.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-shelby-waltz-31301228-6970826.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-shelby-waltz-31301228-6970826.jpg',
          "small": stockImagesUrl + '/nature/pexels-shelby-waltz-31301228-6970826.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-nejc-kosir-108379-338936.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-nejc-kosir-108379-338936.jpg',
          "small": stockImagesUrl + '/nature/pexels-nejc-kosir-108379-338936.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-tamblu-13583844.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-tamblu-13583844.jpg',
          "small": stockImagesUrl + '/nature/pexels-tamblu-13583844.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-philippedonn-1133957.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-philippedonn-1133957.jpg',
          "small": stockImagesUrl + '/nature/pexels-philippedonn-1133957.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-pixabay-325944.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-pixabay-325944.jpg',
          "small": stockImagesUrl + '/nature/pexels-pixabay-325944.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/nature/pexels-veeterzy-38136.jpg',
        "width": 1920,
        "height": 1080,
        "category": "nature",
        "thumbnail": {
          "hd": stockImagesUrl + '/nature/pexels-veeterzy-38136.jpg',
          "small": stockImagesUrl + '/nature/pexels-veeterzy-38136.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-bill-emrich-64742-230785.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-bill-emrich-64742-230785.jpg',
          "small": stockImagesUrl + '/pets/pexels-bill-emrich-64742-230785.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-jagheterjohann-1254140.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-jagheterjohann-1254140.jpg',
          "small": stockImagesUrl + '/pets/pexels-jagheterjohann-1254140.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-chevanon-1108099.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-chevanon-1108099.jpg',
          "small": stockImagesUrl + '/pets/pexels-chevanon-1108099.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-lucaspezeta-2352276.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-lucaspezeta-2352276.jpg',
          "small": stockImagesUrl + '/pets/pexels-lucaspezeta-2352276.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-cong-h-613161-1404819.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-cong-h-613161-1404819.jpg',
          "small": stockImagesUrl + '/pets/pexels-cong-h-613161-1404819.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-lum3n-44775-406014.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-lum3n-44775-406014.jpg',
          "small": stockImagesUrl + '/pets/pexels-lum3n-44775-406014.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-cottonbro-4921291.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-cottonbro-4921291.jpg',
          "small": stockImagesUrl + '/pets/pexels-cottonbro-4921291.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-pixabay-372166.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-pixabay-372166.jpg',
          "small": stockImagesUrl + '/pets/pexels-pixabay-372166.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-dom-bucci-281808-981062.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-dom-bucci-281808-981062.jpg',
          "small": stockImagesUrl + '/pets/pexels-dom-bucci-281808-981062.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-fabtruji-62640.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-fabtruji-62640.jpg',
          "small": stockImagesUrl + '/pets/pexels-fabtruji-62640.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-ilargian-faus-763704-1629781.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-ilargian-faus-763704-1629781.jpg',
          "small": stockImagesUrl + '/pets/pexels-ilargian-faus-763704-1629781.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/pets/pexels-ingewallu-126407.jpg',
        "width": 1920,
        "height": 1080,
        "category": "pets",
        "thumbnail": {
          "hd": stockImagesUrl + '/pets/pexels-ingewallu-126407.jpg',
          "small": stockImagesUrl + '/pets/pexels-ingewallu-126407.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-alex-andrews-271121-3805983.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-alex-andrews-271121-3805983.jpg',
          "small": stockImagesUrl + '/space/pexels-alex-andrews-271121-3805983.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-pixabay-2166.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-pixabay-2166.jpg',
          "small": stockImagesUrl + '/space/pexels-pixabay-2166.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-brunoscramgnon-596134.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-brunoscramgnon-596134.jpg',
          "small": stockImagesUrl + '/space/pexels-brunoscramgnon-596134.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-pixabay-41006.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-pixabay-41006.jpg',
          "small": stockImagesUrl + '/space/pexels-pixabay-41006.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-krisof-1252890.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-krisof-1252890.jpg',
          "small": stockImagesUrl + '/space/pexels-krisof-1252890.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-pixabay-41950.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-pixabay-41950.jpg',
          "small": stockImagesUrl + '/space/pexels-pixabay-41950.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-mikhail-nilov-7672276.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-mikhail-nilov-7672276.jpg',
          "small": stockImagesUrl + '/space/pexels-mikhail-nilov-7672276.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-pixabay-76969.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-pixabay-76969.jpg',
          "small": stockImagesUrl + '/space/pexels-pixabay-76969.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-nivdex-796206.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-nivdex-796206.jpg',
          "small": stockImagesUrl + '/space/pexels-nivdex-796206.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-spacex-586030.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-spacex-586030.jpg',
          "small": stockImagesUrl + '/space/pexels-spacex-586030.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-pixabay-2150.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-pixabay-2150.jpg',
          "small": stockImagesUrl + '/space/pexels-pixabay-2150.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-zelch-12491599.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-zelch-12491599.jpg',
          "small": stockImagesUrl + '/space/pexels-zelch-12491599.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-zelch-12491661.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-zelch-12491661.jpg',
          "small": stockImagesUrl + '/space/pexels-zelch-12491661.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/space/pexels-zelch-12498805.jpg',
        "width": 1920,
        "height": 1080,
        "category": "space",
        "thumbnail": {
          "hd": stockImagesUrl + '/space/pexels-zelch-12498805.jpg',
          "small": stockImagesUrl + '/space/pexels-zelch-12498805.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-ajaybhargavguduru-863988.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-ajaybhargavguduru-863988.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-ajaybhargavguduru-863988.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-262524.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-262524.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-262524.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-chevanon-317157.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-chevanon-317157.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-chevanon-317157.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-274422.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-274422.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-274422.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-gonchifacello-1432039.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-gonchifacello-1432039.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-gonchifacello-1432039.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-390051.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-390051.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-390051.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-163407.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-163407.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-163407.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-47356.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-47356.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-47356.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-221247.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-221247.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-221247.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-victorfreitas-841130.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-victorfreitas-841130.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-victorfreitas-841130.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/sport-hobbies/pexels-pixabay-248547.jpg',
        "width": 1920,
        "height": 1080,
        "category": "sport-hobbies",
        "thumbnail": {
          "hd": stockImagesUrl + '/sport-hobbies/pexels-pixabay-248547.jpg',
          "small": stockImagesUrl + '/sport-hobbies/pexels-pixabay-248547.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-divinetechygirl-1181244.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-divinetechygirl-1181244.jpg',
          "small": stockImagesUrl + '/urban/pexels-divinetechygirl-1181244.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-divinetechygirl-1181263.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-divinetechygirl-1181263.jpg',
          "small": stockImagesUrl + '/urban/pexels-divinetechygirl-1181263.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-fotios-photos-16129703.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-fotios-photos-16129703.jpg',
          "small": stockImagesUrl + '/urban/pexels-fotios-photos-16129703.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-goumbik-574071.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-goumbik-574071.jpg',
          "small": stockImagesUrl + '/urban/pexels-goumbik-574071.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-hebaysal-773471.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-hebaysal-773471.jpg',
          "small": stockImagesUrl + '/urban/pexels-hebaysal-773471.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-lkloeppel-466685.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-lkloeppel-466685.jpg',
          "small": stockImagesUrl + '/urban/pexels-lkloeppel-466685.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-margerretta-548084.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-margerretta-548084.jpg',
          "small": stockImagesUrl + '/urban/pexels-margerretta-548084.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-mati-18678225.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-mati-18678225.jpg',
          "small": stockImagesUrl + '/urban/pexels-mati-18678225.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-nout-gons-80280-378570.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-nout-gons-80280-378570.jpg',
          "small": stockImagesUrl + '/urban/pexels-nout-gons-80280-378570.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-peng-liu-45946-169647.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-peng-liu-45946-169647.jpg',
          "small": stockImagesUrl + '/urban/pexels-peng-liu-45946-169647.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-rickyrecap-1604141.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-rickyrecap-1604141.jpg',
          "small": stockImagesUrl + '/urban/pexels-rickyrecap-1604141.jpg'
        }
      },
      {
        "src": stockImagesUrl + '/urban/pexels-seven11nash-380769.jpg',
        "width": 1920,
        "height": 1080,
        "category": "urban",
        "thumbnail": {
          "hd": stockImagesUrl + '/urban/pexels-seven11nash-380769.jpg',
          "small": stockImagesUrl + '/urban/pexels-seven11nash-380769.jpg'
        }
      }
    ],
  },
  stockVideos: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      {
        "src": stockVideosUrl + "/animals/1182756-hd_1920_1080_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/1182756-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/1182756-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/3144446-hd_1920_1080_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3144446-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3144446-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/4057303-uhd_4096_2160_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/4057303-uhd_4096_2160_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/4057303-uhd_4096_2160_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/animals/2849936-hd_1920_1080_24fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2849936-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2849936-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/3191251-uhd_4096_2160_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3191251-uhd_4096_2160_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3191251-uhd_4096_2160_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/5306154-hd_1920_1080_30fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/5306154-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/5306154-hd_1920_1080_30fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/animals/3040808-uhd_3840_2160_30fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3040808-uhd_3840_2160_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3040808-uhd_3840_2160_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/3568852-hd_1906_1080_30fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3568852-hd_1906_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3568852-hd_1906_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/853913-hd_1920_1080_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/853913-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/853913-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/animals/3045714-hd_1920_1080_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3045714-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3045714-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/3969532-hd_1920_1080_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3969532-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3969532-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/animals/854982-hd_1280_720_25fps.mp4",
        "category": "animals",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/854982-hd_1280_720_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/854982-hd_1280_720_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/1826904-hd_1920_1080_24fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/1826904-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/1826904-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/2516159-hd_1920_1080_24fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2516159-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2516159-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/6804109-hd_2048_1080_25fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/6804109-hd_2048_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/6804109-hd_2048_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/urban/1908424-hd_1920_1080_25fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/1908424-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/1908424-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/2818546-hd_1920_1080_24fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2818546-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2818546-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/6963744-hd_1920_1080_25fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/6963744-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/6963744-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/urban/2099536-hd_1920_1080_30fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2099536-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2099536-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/3121138-hd_1920_1080_24fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3121138-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3121138-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/urban/7534239-hd_1920_1080_25fps.mp4",
        "category": "urban",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/7534239-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/7534239-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/fantasy/5608241-hd_1920_1080_30fps.mp4",
        "category": "fantasy",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/5608241-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/5608241-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/fantasy/7354255-hd_2048_1080_25fps.mp4",
        "category": "fantasy",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/7354255-hd_2048_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/7354255-hd_2048_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/fantasy/7354245-hd_2048_1080_25fps.mp4",
        "category": "fantasy",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/7354245-hd_2048_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/7354245-hd_2048_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/fantasy/9044122-hd_1920_1080_30fps.mp4",
        "category": "fantasy",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/9044122-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/9044122-hd_1920_1080_30fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/food/2431225-hd_1920_1080_24fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2431225-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2431225-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/food/3195650-hd_1920_1080_25fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3195650-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3195650-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/food/3768941-hd_1920_1080_25fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3768941-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3768941-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/food/2620043-hd_1920_1080_25fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2620043-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2620043-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/food/3195728-hd_1920_1080_25fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3195728-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3195728-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/food/3195369-hd_1920_1080_25fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3195369-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3195369-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/food/3209214-hd_1920_1080_25fps.mp4",
        "category": "food",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3209214-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3209214-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/nature/1151290-hd_1920_1080_30fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/1151290-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/1151290-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/nature/5104122-hd_1920_1080_30fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/5104122-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/5104122-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/nature/9116111-hd_1920_1080_25fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/9116111-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/9116111-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/nature/12460001_1920_1080_30fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/12460001_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/12460001_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/nature/9683061-hd_1920_1080_24fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/9683061-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/9683061-hd_1920_1080_24fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/nature/19296729-hd_1920_1080_25fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/19296729-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/19296729-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/nature/856882-hd_1920_1080_24fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/856882-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/856882-hd_1920_1080_24fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/nature/4280455-hd_1920_1080_30fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/4280455-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/4280455-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/nature/857010-hd_1920_1080_30fps.mp4",
        "category": "nature",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/857010-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/857010-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/1851190-hd_1920_1080_25fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/1851190-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/1851190-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/854269-hd_1920_1080_24fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/854269-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/854269-hd_1920_1080_24fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/856857-hd_2048_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/856857-hd_2048_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/856857-hd_2048_1080_30fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/space/2695085-hd_1920_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/2695085-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/2695085-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/855835-hd_1920_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/855835-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/855835-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/856987-hd_1920_1080_24fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/856987-hd_1920_1080_24fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/856987-hd_1920_1080_24fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/space/3194277-hd_1920_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3194277-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3194277-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/856309-hd_1920_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/856309-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/856309-hd_1920_1080_30fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/space/854228-hd_1920_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/854228-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/854228-hd_1920_1080_30fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/space/856359-hd_1920_1080_30fps.mp4",
        "category": "space",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/856359-hd_1920_1080_30fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/856359-hd_1920_1080_30fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/sport/3125907-hd_1920_1080_25fps.mp4",
        "category": "sport",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3125907-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3125907-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/sport/4109367-hd_1920_1080_25fps.mp4",
        "category": "sport",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/4109367-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/4109367-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/sport/4440937-hd_1920_1080_25fps.mp4",
        "category": "sport",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/4440937-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/4440937-hd_1920_1080_25fps.jpg",
        }
      },

      {
        "src": stockVideosUrl + "/sport/3196221-hd_1920_1080_25fps.mp4",
        "category": "sport",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/3196221-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/3196221-hd_1920_1080_25fps.jpg",
        }
      },
      {
        "src": stockVideosUrl + "/sport/4274798-hd_1920_1080_25fps.mp4",
        "category": "sport",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/4274798-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/4274798-hd_1920_1080_25fps.jpg",
        }
        },
      {
        "src": stockVideosUrl + "/sport/992624-hd_1920_1080_25fps.mp4",
        "category": "sport",
        "width": 1920,
        "height": 1080,
        "thumbnail": {
          "hd": stockImagesUrl + "/video-thumbs/992624-hd_1920_1080_25fps.jpg",
          "small": stockImagesUrl + "/video-thumbs/992624-hd_1920_1080_25fps.jpg",
        }
      }

    ],
    relatedTags: [],
  },
  uploadImages: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
  },
  uploadVideos: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
    relatedTags: [],
  },
  stockEmojis: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_happy_1325173.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_love_1325176.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_embarrass_1325177.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_cry_1325179.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_surprise_1325174.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_dead_1325175.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_shy_1325169.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_sleepy_1325168.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_angry_1325170.svg',
      },
      {
        src: 'https://assets.adlaunch.com/elements/iconfinder_sad_1325172.svg',
      },
    ],
  },
  stockShapes: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [
      { src: "sample-shapes/accessories.svg" }, { src: "sample-shapes/bathroom.svg" }, { src: "sample-shapes/car.svg" }, { src: "sample-shapes/condiments.svg" }, { src: "sample-shapes/electronics.svg" }, { src: "sample-shapes/flowers.svg" }, { src: "sample-shapes/hardware.svg" }, { src: "sample-shapes/insects.svg" }, { src: "sample-shapes/livingroom.svg" }, { src: "sample-shapes/places.svg" }, { src: "sample-shapes/reptiles.svg" }, { src: "sample-shapes/stones.svg" }, { src: "sample-shapes/travelling.svg" }, { src: "sample-shapes/wedding.svg" },
      { src: "sample-shapes/accomodations.svg" }, { src: "sample-shapes/beach.svg" }, { src: "sample-shapes/carparts.svg" }, { src: "sample-shapes/confetti2.svg" }, { src: "sample-shapes/entertainment.svg" }, { src: "sample-shapes/food.svg" }, { src: "sample-shapes/hardware2.svg" }, { src: "sample-shapes/insects2.svg" }, { src: "sample-shapes/market.svg" }, { src: "sample-shapes/plants.svg" }, { src: "sample-shapes/restaurant.svg" }, { src: "sample-shapes/street.svg" }, { src: "sample-shapes/travelling2.svg" }, { src: "sample-shapes/weights.svg" },
      { src: "sample-shapes/airport.svg" }, { src: "sample-shapes/beach2.svg" }, { src: "sample-shapes/church.svg" }, { src: "sample-shapes/construction.svg" }, { src: "sample-shapes/fabrics.svg" }, { src: "sample-shapes/football.svg" }, { src: "sample-shapes/health.svg" }, { src: "sample-shapes/instruments.svg" }, { src: "sample-shapes/market3.svg" }, { src: "sample-shapes/police.svg" }, { src: "sample-shapes/school.svg" }, { src: "sample-shapes/subjects.svg" }, { src: "sample-shapes/tv.svg" }, { src: "sample-shapes/workplace.svg" },
      { src: "sample-shapes/animals.svg" }, { src: "sample-shapes/beautysalon.svg" }, { src: "sample-shapes/cinema.svg" }, { src: "sample-shapes/conversation.svg" }, { src: "sample-shapes/fantasticanimals.svg" }, { src: "sample-shapes/fruits.svg" }, { src: "sample-shapes/historiceras.svg" }, { src: "sample-shapes/jobs.svg" }, { src: "sample-shapes/mathgeometry.svg" }, { src: "sample-shapes/police2.svg" }, { src: "sample-shapes/science.svg" }, { src: "sample-shapes/supermarket.svg" }, { src: "sample-shapes/universe.svg" },
      { src: "sample-shapes/animalworld.svg" }, { src: "sample-shapes/bedroom.svg" }, { src: "sample-shapes/urban.svg" }, { src: "sample-shapes/cooking.svg" }, { src: "sample-shapes/fashion.svg" }, { src: "sample-shapes/garden.svg" }, { src: "sample-shapes/history.svg" }, { src: "sample-shapes/kitchen.svg" }, { src: "sample-shapes/meat.svg" }, { src: "sample-shapes/politics.svg" }, { src: "sample-shapes/senses.svg" }, { src: "sample-shapes/tastes.svg" }, { src: "sample-shapes/vegetables.svg" },
      { src: "sample-shapes/architecture.svg" }, { src: "sample-shapes/beverages.svg" }, { src: "sample-shapes/cleaning.svg" }, { src: "sample-shapes/cosmetics.svg" }, { src: "sample-shapes/fashion2.svg" }, { src: "sample-shapes/gardening.svg" }, { src: "sample-shapes/hobbies.svg" }, { src: "sample-shapes/landscape.svg" }, { src: "sample-shapes/mechanic.svg" }, { src: "sample-shapes/port.svg" }, { src: "sample-shapes/shapes.svg" }, { src: "sample-shapes/tennis.svg" }, { src: "sample-shapes/vegetables2.svg" },
      { src: "sample-shapes/aroundthehouse.svg" }, { src: "sample-shapes/birds.svg" }, { src: "sample-shapes/clothes.svg" }, { src: "sample-shapes/dessert.svg" }, { src: "sample-shapes/festivity.svg" }, { src: "sample-shapes/gasstation.svg" }, { src: "sample-shapes/home.svg" }, { src: "sample-shapes/landscape2.svg" }, { src: "sample-shapes/metals.svg" }, { src: "sample-shapes/prison.svg" }, { src: "sample-shapes/shoes.svg" }, { src: "sample-shapes/terms.svg" }, { src: "sample-shapes/volleyball.svg" },
      { src: "sample-shapes/art.svg" }, { src: "sample-shapes/bodyparts.svg" }, { src: "sample-shapes/colors.svg" }, { src: "sample-shapes/dessert2.svg" }, { src: "sample-shapes/festivity2.svg" }, { src: "sample-shapes/gasstation2.svg" }, { src: "sample-shapes/hospital.svg" }, { src: "sample-shapes/law.svg" }, { src: "sample-shapes/music.svg" }, { src: "sample-shapes/prison2.svg" }, { src: "sample-shapes/shopping.svg" }, { src: "sample-shapes/tobacco.svg" }, { src: "sample-shapes/watersports.svg" },
      { src: "sample-shapes/baby.svg" }, { src: "sample-shapes/bones.svg" }, { src: "sample-shapes/colors2.svg" }, { src: "sample-shapes/disco.svg" }, { src: "sample-shapes/firemen.svg" }, { src: "sample-shapes/geography.svg" }, { src: "sample-shapes/hotel.svg" }, { src: "sample-shapes/lexicon.svg" }, { src: "sample-shapes/news.svg" }, { src: "sample-shapes/professions.svg" }, { src: "sample-shapes/shops.svg" }, { src: "sample-shapes/tools.svg" }, { src: "sample-shapes/weapons.svg" },
      { src: "sample-shapes/bank.svg" }, { src: "sample-shapes/breakfast.svg" }, { src: "sample-shapes/computer.svg" }, { src: "sample-shapes/economy.svg" }, { src: "sample-shapes/fish.svg" }, { src: "sample-shapes/groceriesshops.svg" }, { src: "sample-shapes/house.svg" }, { src: "sample-shapes/library.svg" }, { src: "sample-shapes/nicknames.svg" }, { src: "sample-shapes/realestate.svg" }, { src: "sample-shapes/smartphone.svg" }, { src: "sample-shapes/tools2.svg" }, { src: "sample-shapes/weather.svg" },
      { src: "sample-shapes/bar.svg" }, { src: "sample-shapes/calendar.svg" }, { src: "sample-shapes/computerscience.svg" }, { src: "sample-shapes/economy2.svg" }, { src: "sample-shapes/fishes.svg" }, { src: "sample-shapes/gym.svg" }, { src: "sample-shapes/icecreamparlor.svg" }, { src: "sample-shapes/life.svg" }, { src: "sample-shapes/pharmacy.svg" }, { src: "sample-shapes/relations.svg" }, { src: "sample-shapes/sports.svg" }, { src: "sample-shapes/toys.svg" }, { src: "sample-shapes/weather2.svg" },
      { src: "sample-shapes/basketball.svg" }, { src: "sample-shapes/calendar2.svg" }, { src: "sample-shapes/concepts.svg" }, { src: "sample-shapes/education.svg" }, { src: "sample-shapes/fishing.svg" }, { src: "sample-shapes/hairsalon.svg" }, { src: "sample-shapes/ideas.svg" }, { src: "sample-shapes/life2.svg" }, { src: "sample-shapes/photography.svg" }, { src: "sample-shapes/religions.svg" }, { src: "sample-shapes/stadium.svg" }, { src: "sample-shapes/trainstation.svg" }, { src: "sample-shapes/web.svg" },
    ],
  },
  favoriteImages: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
  },
  favoriteVideos: {
    didInvalidate: false,
    isFetching: false,
    currentPage: 0,
    entries: [],
    relatedTags: [],
  },
};

export default function footage(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_FOOTAGE: {
      const key = action.payload.key;
      return {
        ...state,
        [key]: {
          ...state[key],
          entries: [action.payload.data, ...state[key].entries],
        }
      };
    }
    case types.FOOTAGE_REQUEST_IMAGES: {
      const key = stateKeys.images[action.payload.filter];

      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: true,
          searchTerm: '',
        },
      };
    }

    case types.FOOTAGE_RECEIVE_IMAGES: {
      const {
        filter,
        entries,
        searchTerm,
        page,
        receivedAt,
      } = action.payload;

      const imageFootageKey = stateKeys.images[filter];

      /**
       * New page number is set into place only if there are
       * results returned from the call.
       * At the moment API server returns a status 200 with
       * empty entries array if no footage is found for the
       * offset / pagenumber.
       * If pagenumber would be always set, the state would
       * be full of empty arrays and currentpage would be wrong.
       */
      const oldEntries = state[imageFootageKey].entries;
      const newEntries = page > 0 ? [...oldEntries, entries] : [entries];

      return {
        ...state,
        [imageFootageKey]: {
          entries: newEntries,
          isFetching: false,
          didInvalidate: false,
          lastUpdated: receivedAt,
          currentPage: page,
          searchTerm,
        },
      };
    }

    case types.FOOTAGE_RELATED_TAGS_RECEIVE: {
      return {
        ...state,
        relatedTags: action.payload.relatedTags,
      };
    }
    case types.FOOTAGE_RECEIVE_SVG: {
      const { filter, svgData, src } = action.payload;
      if (filter === 'emojis') {
        const { entries } = state.stockEmojis;
        const newEntries = entries.map((shape) => {
          if (shape.src !== src) return shape;
          return {
            ...shape,
            svgData,
          };
        });
        return {
          ...state,
          stockEmojis: {
            ...state.stockEmojis,
            entries: newEntries,
          },
        };
      }
      const { entries } = state.stockShapes;
      const newEntries = entries.map((shape) => {
        if (shape.src !== src) return shape;
        return {
          ...shape,
          svgData,
        };
      });
      return {
        ...state,
        stockEmojis: {
          ...state.stockEmojis,
          entries: newEntries,
        },
      };
    }

    case types.FOOTAGE_REQUEST_VIDEOS: {
      const key = stateKeys.videos[action.payload.filter];

      return {
        ...state,
        [key]: {
          ...state[key],
          isFetching: true,
          searchTerm: '',
        },
      };
    }
    case types.FOOTAGE_ADD_TO_FAVORITE: {
      const { favFootage, type, favoriteId } = action.payload;
      if (type === 'video') {
        const { entries } = state.favoriteVideos;
        const alreadyFavorited = entries[0] || [];
        return {
          ...state,
          favoriteVideos: {
            ...state.favoriteVideos,
            entries: [[...alreadyFavorited, { ...favFootage, favoriteId }]],
          },
        };
      }
      const { entries } = state.favoriteImages;
      const alreadyFavorited = entries[0] || [];

      return {
        ...state,
        favoriteImages: {
          ...state.favoriteImages,
          entries: [[...alreadyFavorited, { ...favFootage, favoriteId, isFav: true }]],
        },
      };
    }
    case types.FOOTAGE_REMOVE_FROM_FAVORITE: {
      const { favoriteId, type } = action.payload;
      if (type === 'video') {
        const { entries } = state.favoriteVideos;
        const newEntries = entries.map(page =>
          page.filter(entry => entry.favoriteId !== favoriteId));

        return {
          ...state,
          favoriteVideos: {
            ...state.favoriteVideos,
            entries: newEntries,
          },
        };
      }
      const { entries } = state.favoriteImages;
      const newEntries = entries.map(page =>
        page.filter(entry => entry.favoriteId !== favoriteId));
      return {
        ...state,
        favoriteImages: {
          ...state.favoriteImages,
          entries: newEntries,
        },
      };
    }

    case types.FOOTAGE_CHANGE_SORTING: {
      return {
        ...state,
        sorting: action.payload.type,
      };
    }

    case types.FOOTAGE_RECEIVE_VIDEOS: {
      const videoFootageKey = stateKeys.videos[action.payload.filter];
      const stateData = state[videoFootageKey];

      /**
       * New page number is set into place only if there are
       * results returned from the call.
       * At the moment API server returns a status 200 with
       * empty entries array if no footage is found for the
       * offset / pagenumber.
       * If pagenumber would be always set, the state would
       * be full of empty arrays and currentpage would be wrong.
       */
      let newPageNumber = stateData.currentPage;

      const newEntries = [...stateData.entries];

      if (action.payload.entries.length > 0) {
        newEntries[action.payload.page] = action.payload.entries;
        newPageNumber = action.payload.page;
      }

      const newState = {
        ...state,
        [videoFootageKey]: {
          ...state[videoFootageKey],
          entries: newEntries,
          isFetching: false,
          didInvalidate: false,
          lastUpdated: action.payload.receivedAt,
          currentPage: newPageNumber,
        },
      };


      return newState;
    }

    case types.FOOTAGE_SEARCH_REQUEST: {
      const {
        filter,
        type,
      } = action.payload;
      const videoFootageKey = stateKeys[type][filter];
      return {
        ...state,
        [videoFootageKey]: {
          ...state[videoFootageKey],
          isFetching: true,
        },
      };
    }

    case types.FOOTAGE_SEARCH_RECEIVE: {
      const {
        filter,
        type,
        entries,
        relatedTags,
        searchTerm,
        page,
      } = action.payload;
      const videoFootageKey = stateKeys[type][filter];

      const oldEntries = state[videoFootageKey].entries;
      const newEntries = page > 0 ? [...oldEntries, entries] : [entries];

      const newState = {
        ...state,
        [videoFootageKey]: {
          ...state[videoFootageKey],
          entries: newEntries,
          relatedTags,
          isFetching: false,
          didInvalidate: true,
          lastUpdated: action.payload.receivedAt,
          searchTerm,
          currentPage: page,
        },
      };

      return newState;
    }

    case types.FOOTAGE_INVALIDATE_VIDEOS: {
      const footageKey = stateKeys.video[action.payload.filter];
      return {
        ...state,
        [footageKey]: {
          ...state[footageKey],
          didInvalidate: true,
        },
      };
    }

    case types.FOOTAGE_INVALIDATE_IMAGES: {
      const footageKey = stateKeys.image[action.payload.filter];
      return {
        ...state,
        [footageKey]: {
          ...state[footageKey],
          didInvalidate: true,
        },
      };
    }

    case types.FOOTAGE_TAG_SEARCH_REQUEST:
      return {
        ...state,
        searchSuggestions: [],
        searchIsFetching: true,
      };

    case types.FOOTAGE_TAG_SEARCH_RECEIVE:
      return {
        ...state,
        searchSuggestions: action.payload.entries,
        searchIsFetching: false,
      };

    case types.FOOTAGE_TAG_SEARCH_CLEAR:
      return {
        ...state,
        searchSuggestions: [],
        searchIsFetching: false,
      };

    case types.UPLOAD_DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        uploadVideos: {
          ...state.uploadVideos,
          entries: removeEntryByUid(state.uploadVideos.entries, action.payload.uploadUid),
        },
      };

    case types.UPLOAD_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImages: {
          ...state.uploadImages,
          entries: removeEntryByUid(state.uploadImages.entries, action.payload.uploadUid),
        },
      };

    default:
      return state;
  }
}
