import * as types from '@constants/actionTypes';

const initialState = {
  chatOpen: false,
  sideMenuOpen: false,
  welcomeScreenOpen: false,
  settingOpen: false,
  shortcutsOpen: false,
  showGuideX: false,
  showGuideY: false,
  lightTheme: true,
  saving: false,
  stockImagesLoaded: true,
  stockVideoLoaded: true,
  isRendering: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.APP_TRIGGER_NOTIFICATION:
      return {
        ...state,
      };

    case types.APP_TOGGLE_CHAT:
      return {
        ...state,
        chatOpen: !state.chatOpen,
      };

    case types.APP_HIDE_CHAT:
      return {
        ...state,
        chatOpen: false,
      };

    case types.APP_TOGGLE_SIDE_MENU:
      return {
        ...state,
        sideMenuOpen: !state.sideMenuOpen,
        settingOpen: false,
      };

    case types.APP_TOGGLE_SHORTCUTS:
      return {
        ...state,
        shortcutsOpen: !state.shortcutsOpen,
      };

    case types.APP_SET_AUTOSAVING:
      return {
        ...state,
        saving: action.payload.saving,
      };

    case types.APP_SET_GUIDES:
      return {
        ...state,
        showGuideX: action.payload.showGuideX,
        showGuideY: action.payload.showGuideY,
      };

    case types.APP_TOGGLE_WELCOME_SCREEN:
    case types.TUTORIAL_TOGGLE:
      return {
        ...state,
        welcomeScreenOpen: !state.welcomeScreenOpen,
      };

    case types.APP_TOGGLE_USER_ACCOUNT_MANAGER:
      return {
        ...state,
        settingOpen: !state.settingOpen,
      };

    case types.APP_TOGGLE_LIGHT_THEME:
      return {
        ...state,
        lightTheme: !state.lightTheme,
      };

    case types.USER_RECEIVE:
      return {
        ...state,
        welcomeScreenOpen: Boolean(action.payload.isTutorial),
      };
    case types.APP_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
